<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <div class="message-wrap row">
      <div class="">
        <pf-button :class="'close-btn'" @click="setState">x</pf-button>
        <div class="logo-cere-oferta">
          <img src="@/assets/images/response_contact.svg" alt="Contact" />
        </div>
        <h4 class="fast-order-message">
          {{ responseMessage }}
        </h4>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import PfButton from "@/components/buttons/PfButton.vue";
export default {
  components: {
    PfButton,
  },
  computed: {
    showModal() {
      return this.$store.getters["forms/modalResponseContact"];
    },
    responseMessage() {
      return this.$store.getters["forms/contactMessage"];
    },
  },
  methods: {
    setState() {
      this.$store.dispatch("forms/responseContact", false);
    },
  },
  unmounted() {
    this.$store.dispatch("forms/responseContact", false);
  },
};
</script>

<style scoped>
.title-comanda {
  font-family: "Raleway Bold";
  font-size: 24px;
}

.subtitle-comanda {
  font-family: "Raleway Bold";
  font-size: 12px;
  color: #646464;
  max-width: 448px;
  display: inline-block;
  margin-bottom: 20px;
}

.logo-cere-oferta img {
  /* width: 187px; */
  margin-bottom: 10px;
}

.message-wrap {
  text-align: center;
  padding: 50px 0px;
}

.fast-order-message {
  color: #000000;
  font-size: 24px;
  text-align: center;
  padding: 20px 0px;
  font-family: "Raleway Bold";
  width: 70%;
  margin: 0 auto;
}

::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  background: #fff;
  border-radius: 25px;
  width: auto;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

@media (max-width: 768px) {
  .modal-content {
    width: auto;
  }

  .fast-order-message {
    width: auto;
  }
}

@media (max-width: 576px) {
  .subtitle-comanda {
    /* max-width: 320px; */
  }
}
</style>
